<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :estado-cliente="formattedItem.estadoCliente"
            :estado-sistema="formattedItem.estadoSistema"
          />
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SistemaViewData'
import { nonEmpty } from '@/utils/templates'
import { TABLA } from '@/utils/consts'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import { get } from 'vuex-pathify'
import ExtraSubtitle from '../../components/SistemaExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
        irCliente: {
          title: 'Ir al cliente',
          visible: true,
        }
      },
      avisos: '',
      detailOrdenesTrabajo: {},
      clienteVisible: false
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    networkOnline: get('network/online'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.cliente.idcliente
        item.title = `${item.sistema.descripcion} (${item.sistema.codigo})`
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.cliente.nombre} (${item.cliente.idcliente})
          ${this.$offline.clienteDireccion.formatearDireccion(item.cliente_direccion)}
          ${item.cliente_direccion.observacion}
          Sistema de ${item.tsistema.descripcion}`)
        item.estadoCliente = item.cliente.estado
        item.estadoSistema = item.sistema.estado
        item.alerts = []
        if (this.avisos) {
          item.alerts.push({ value: this.avisos, type: 'warning' })
        }
        if (!item.cliente_direccion.latitud || !item.cliente_direccion.longitud) {
          item.alerts.push({
            value: 'Sistema sin geolocalización',
            type: 'warning',
            options: {
              persistent: true,
              actions: [{ text: 'Capturar', flat: true, handler: this.geolocalizar }],
            },
          })
        }
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  watch: {
    networkOnline (newValue) {
      this.$set(this.detailOrdenesTrabajo, 'disabled', !newValue)
      this.clienteVisible = (
        !!newValue && !!this.item?.dataset.cliente.idcliente && this.hasViewPerm(this.permissions.cliente.id)
      )
      this.toolbarOptions.irCliente.visible = this.clienteVisible
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.toolbarOptions.geolocalizar.visible = this.hasEditPerm(this.permissions.sistema.id)
      this.toolbarOptions.irCliente.visible = this.clienteVisible
      this.avisos = await this.$offline.clienteAviso.avisos(
        this.item.dataset.cliente.idcliente,
        this.item.dataset.sistema.idsistema,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial
      )
      this.title = `Sistema Nº${this.item.dataset.sistema.codigo}`
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = [
        { name: 'sistema.codigo', label: 'Código' },
        { name: 'sistema.nabonado1', label: 'Nº abonado' },
        { name: 'sistema.descripcion', label: 'Descripción' },
        { name: 'sistema.idcliente', label: 'Nº cliente' },
        { name: 'sistema.fconexion', label: 'Conexión', filter: this.$options.filters.shortDate },
        { name: 'direccion', label: 'Dirección' },
        { name: 'cliente_direccion.observacion', label: 'Observaciones' },
        { name: 'zona.descripcion', label: 'Zona' },
        { name: 'sistema.horario', label: 'Horario' },
        { name: 'sistema.grado_seguridad_descripcion', label: 'Grado' },
        { name: 'sistema.tactividad_descripcion', label: 'Sector' },
        { name: 'tsistema.descripcion', label: 'Tipo de sistema' },
        { name: 'sistema.central_alarma_nombre', label: 'Central de alarma' },
        { name: 'forma_pago.descripcion', label: 'Forma de pago', visible: this.hasViewPerm(this.permissions.clientes.verFormaPago) },
        { name: 'sistema.agente_nombre', label: 'Agente' },
        { name: 'sistema.comercial_nombre', label: 'Comercial' },
        { name: 'sistema.observaciones', label: 'Observaciones' },
      ]
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectSistema(this, this.routeParams.idsistema)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.toolbarOptions.map.visible = !!this.item.dataset.cliente_direccion.latitud && !!this.item.dataset.cliente_direccion.longitud
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // contactos
      const detailContactos = this.addDetail(
        'contactos', 'Personas de contacto', 'Personas de contacto del sistema', 'personaContacto'
      )
      const datasetContactos = await Data.selectSistemaTelefonoCount(this, this.routeParams.idsistema)
      detailContactos.badge = datasetContactos.length || 0
      // revisiones
      const detailRevisiones = this.addDetail(
        'revisiones', 'Revisiones', 'Revisiones programadas del sistema', 'revision'
      )
      const datasetRevisiones = await Data.selectRevisionesCount(this, this.routeParams.idsistema)
      let countRevisiones = 0
      let totalsRevisiones = []
      for (const rowRevision of datasetRevisiones) {
        countRevisiones += rowRevision.count
        totalsRevisiones.push(
          `${rowRevision.tactuacion.descripcion} ${rowRevision.periodo_revision.descripcion} (${rowRevision.count.toFixed()})`
        )
      }
      detailRevisiones.badge = countRevisiones || 0
      detailRevisiones.totals = totalsRevisiones.join(', ')
      // material
      if (this.hasViewPerm(this.permissions.materialSistema.id)) {
        const detailMaterialSistema = this.addDetail(
          'materialSistema', 'Material de sistema', 'Material instalado en el sistema', 'materialInstalar'
        )
        const datasetMaterialSistema = await Data.selectMaterialSistemaCount(this, this.routeParams.idsistema)
        detailMaterialSistema.badge = datasetMaterialSistema.count || 0
      }
      // órdenes de trabajo online
      this.detailOrdenesTrabajo = this.addDetail(
        'ordenes', 'Órdenes de trabajo', 'Órdenes de trabajo del sistema', 'orden', !this.networkOnline
      )
      // servicios contratados
      const detailServiciosContratados = this.addDetail(
        'serviciosContratados', 'Servicios contratados', 'Servicios contratados y cuotas de mantenimiento', 'servicioContratado'
      )
      const datasetServiciosContratados = await Data.selectSistemaCuotaCount(this, this.routeParams.idsistema)
      detailServiciosContratados.badge = datasetServiciosContratados.count || 0
      // Vias de comunicación
      const detailViasComunicacion = this.addDetail(
        'viasComunicacion', 'Vías de comunicación', 'Vías de comunicación del sistema', 'viaComunicacion'
      )
      const totalViasComunicacion = await this.$offline.sistemaTviacomunicacion.selectDeSistema(this.routeParams.idsistema)
      detailViasComunicacion.badge = totalViasComunicacion.length || 0
      // subsistemas
      const detailSubsistemas = this.addDetail(
        'subsistemas', 'Subsistemas', '', 'subsistema'
      )
      const datasetSubsistemas = await Data.selectSubsisCount(this, this.routeParams.idsistema)
      detailSubsistemas.badge = datasetSubsistemas.count || 0
      // ficheros
      if (this.hasViewPerm(this.permissions.sistemaAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'ficheros', 'Adjuntos', 'Adjuntos del sistema', 'attach'
        )
        const datasetFicheros = await Data.selectFicherosCount(this, this.routeParams.idsistema)
        detailFicheros.badge = datasetFicheros.count || 0
      }
      // datos
      if (this.hasViewPerm(this.permissions.sistemaDato.id)) {
        const detailDatoSistema = this.addDetail(
          'datos', 'Datos', 'Datos de sistema', 'datoPersonalizado'
        )
        const datasetDatoSistema = await Data.selectDatoSistemaCount(this, this.routeParams.idsistema)
        detailDatoSistema.badge = datasetDatoSistema.count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'contactos') {
        this.$appRouter.push({
          name: 'offline__cliente-telefono-list',
          params: {
            idcliente: this.item.dataset.cliente.idcliente,
          },
          query: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'revisiones') {
        this.$appRouter.push({
          name: 'offline__sistema-mant-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'subsistemas') {
        this.$appRouter.push({
          name: 'offline__subsis-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'ficheros') {
        this.$appRouter.push({
          name: 'offline__sistema-fichero-list',
          params: {
            idtabla: TABLA.sistema.idtabla,
            id: parseInt(this.routeParams.idsistema),
          },
        })
      } else if (data.detail.name === 'materialSistema') {
        this.$appRouter.push({
          name: 'offline__material-sistema-subsis-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'ordenes') {
        this.$appRouter.push({
          name: 'sistemas__ordenes-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'serviciosContratados') {
        this.$appRouter.push({
          name: 'offline__sistema-cuota-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'datos') {
        this.$appRouter.push({
          name: 'offline__sistemas__dato-sistema-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'viasComunicacion') {
        this.$appRouter.push({
          name: 'offline__sistemas__sistema-tviacomunicacion-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.cliente_direccion.latitud, this.item.dataset.cliente_direccion.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      } else if (option === this.toolbarOptions.irCliente) {
        if (this.networkOnline) {
          this.$appRouter.push({
            name: 'clientes__cliente-view',
            params: {
              idcliente: this.item.dataset.cliente.idcliente,
            },
          })
        } else {
          this.$alert.showSnackbarError('Necesitas conexión a internet.')
        }
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del sistema "${this.$offline.clienteDireccion.formatearDireccion(this.item.dataset.cliente_direccion)}"?`)
        if (res) {
          await Data.geolocalizar(
            this,
            this.item.dataset.cliente_direccion.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
  },
}
</script>
