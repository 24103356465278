import { TABLA } from '@/utils/consts'

export default {
  async _addSubqueriesSistema (Vue, rows) {
    for (let row of rows) {
      row.direccion = await Vue.$offline.clienteDireccion.formatearDireccion(row.cliente_direccion)
    }
    return rows
  },
  async selectSistema (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    const rows = await Vue.$offline.db
      .select()
      .from(tables.sistema)
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .innerJoin(
        tables.tsistema,
        tables.sistema.idtsistema.eq(tables.tsistema.idtsistema)
      )
      .innerJoin(
        tables.forma_pago,
        tables.sistema.idforma_pago.eq(tables.forma_pago.idforma_pago)
      )
      .leftOuterJoin(
        tables.cliente_direccion,
        tables.sistema.idcliente_direccion_sist.eq(tables.cliente_direccion.idcliente_direccion)
      )
      .leftOuterJoin(
        tables.zona,
        tables.cliente_direccion.idzona_tecnico.eq(tables.zona.idzona)
      )
      .where(tables.sistema.idsistema.eq(idsistema))
      .exec()
    return (await this._addSubqueriesSistema(Vue, rows))[0]
  },
  async selectSistemaTelefonoCount (Vue, idsistema) {
    return await Vue.$offline.sistemaTelefono.selectContactosDelSistema(idsistema)
  },
  async selectSistemaTviacomunicacion (Vue, idsistema) {
    return await Vue.$offline.sistemaTelefono.selectContactosDelSistema(idsistema)
  },
  async selectRevisionesCount (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select(
        tables.tactuacion.descripcion,
        tables.periodo_revision.descripcion,
        Vue.$offline.db.fn.count(tables.sistema_mant.idsistema_mant).as('count'),
      )
      .from(tables.sistema_mant)
      .innerJoin(
        tables.tactuacion,
        tables.sistema_mant.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .innerJoin(
        tables.periodo_revision,
        tables.sistema_mant.idperiodo_revision.eq(tables.periodo_revision.idperiodo_revision)
      )
      .groupBy(
        tables.tactuacion.descripcion,
        tables.periodo_revision.descripcion,
      )
      .where(
        Vue.$offline.db.op.and(
          tables.sistema_mant.idsistema.eq(idsistema),
          tables.sistema_mant.estado.gt(0),
          tables.sistema_mant.activar.eq(true),
        )
      )
      .exec()
  },
  async selectMaterialSistemaCount(Vue, idsistema){
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.subsis.idsubsis).as('count'))
      .from(tables.material_sistema)
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.material_sistema.estado.gt(0),
          tables.material_sistema.fdesinstalacion.isNull(),
          tables.subsis.idsistema.eq(idsistema),
          tables.subsis.estado.gt(0),
        )
      )
      .exec())[0]
  },
  async selectSubsisCount (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.subsis.idsubsis).as('count'))
      .from(tables.subsis)
      .where(
        Vue.$offline.db.op.and(
          tables.subsis.idsistema.eq(idsistema),
          tables.subsis.estado.gt(0),
        )
      )
      .exec())[0]
  },
  async selectFicherosCount (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.fichero.idfichero).as('count'))
      .from(tables.fichero)
      .where(
        Vue.$offline.db.op.and(
          tables.fichero.idtabla.eq(TABLA.sistema.idtabla),
          tables.fichero.id.eq(idsistema),
        )
      )
      .exec())[0]
  },
  async geolocalizar (Vue, idclienteDireccion, latitud, longitud) {
    await Vue.$offline.clienteDireccion.updateSync({
      idcliente_direccion: idclienteDireccion,
      latitud,
      longitud,
    })
  },
  async selectSistemaCuotaCount (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    // esto lo hace parteTrabajo también
    const idConceptoCuotaDeMantenimiento = await Vue.$offline.conceptoCuotaTservicio.idConceptoCuotaDeMantenimiento()
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.sistema_cuota.idsistema_cuota).as('count'))
      .from(tables.sistema_cuota)
      .innerJoin(
        tables.concepto_cuota,
        tables.sistema_cuota.idconcepto_cuota.eq(tables.concepto_cuota.idconcepto_cuota)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.sistema_cuota.estado.gt(0),
          tables.sistema_cuota.idconcepto_cuota.in(idConceptoCuotaDeMantenimiento),
          tables.sistema_cuota.idsistema.eq(idsistema)
        )
      )
      .exec())[0]
  },
  async selectDatoSistemaCount (Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.dato_sistema.iddato_sistema).as('count'))
      .from(tables.dato_sistema)
      .where(
        Vue.$offline.db.op.and(
          tables.dato_sistema.idsistema.eq(idsistema),
          tables.dato_sistema.estado.gt(0),
        )
      )
      .exec())[0]
  },
}
